.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    flex-direction: column;
  }
  
  .header {
    background-color: rgb(142, 150, 156);
    height: 40px;
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }
  
  .grid {
    position: relative;
  }
  
  .cellContainer {
    position: absolute;
    border: 1px dashed gray;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cell {
    width: 100%;
    height: 100%;
  }
  